import { Barcode, BarcodePicker, Camera, CameraSettings, ScanSettings, SearchArea, SingleImageModeSettings } from "..";
import { convertAttribute, ConvertedAttribute } from "./attributeConverter";
import { BarcodePickerView } from "./barcodePickerView";
import { Attribute, AttributeDescriptor } from "./schema";

export class LazyAttributeConverter {
  constructor(
    private readonly schema: { [key in Attribute]: AttributeDescriptor },
    private readonly view: BarcodePickerView
  ) {}

  get [Attribute.ACCESS_CAMERA](): boolean {
    return this.convertToPrimary(Attribute.ACCESS_CAMERA);
  }

  get [Attribute.CAMERA](): Camera {
    return this.convertToPrimary(Attribute.CAMERA);
  }

  get [Attribute.CAMERA_SETTINGS](): CameraSettings {
    return this.convertToPrimary(Attribute.CAMERA_SETTINGS);
  }

  get [Attribute.CAMERA_TYPE](): Camera.Type {
    return this.convertToPrimary(Attribute.CAMERA_TYPE);
  }

  get [Attribute.CONFIGURE](): boolean {
    return this.convertToPrimary(Attribute.CONFIGURE);
  }

  get [Attribute.ENABLE_CAMERA_SWITCHER](): boolean {
    return this.convertToPrimary(Attribute.ENABLE_CAMERA_SWITCHER);
  }

  get [Attribute.ENABLE_PINCH_TO_ZOOM](): boolean {
    return this.convertToPrimary(Attribute.ENABLE_PINCH_TO_ZOOM);
  }

  get [Attribute.ENABLE_TAP_TO_FOCUS](): boolean {
    return this.convertToPrimary(Attribute.ENABLE_TAP_TO_FOCUS);
  }

  get [Attribute.ENABLE_TORCH_TOGGLE](): boolean {
    return this.convertToPrimary(Attribute.ENABLE_TORCH_TOGGLE);
  }

  get [Attribute.GUI_STYLE](): BarcodePicker.GuiStyle {
    return this.convertToPrimary(Attribute.GUI_STYLE);
  }

  get [Attribute.LASER_AREA](): SearchArea {
    return this.convertToPrimary(Attribute.LASER_AREA);
  }

  get [Attribute.PLAY_SOUND_ON_SCAN](): boolean {
    return this.convertToPrimary(Attribute.PLAY_SOUND_ON_SCAN);
  }

  get [Attribute.SCANNING_PAUSED](): boolean {
    return this.convertToPrimary(Attribute.SCANNING_PAUSED);
  }

  get [Attribute.SINGLE_IMAGE_MODE_SETTINGS](): SingleImageModeSettings {
    return this.convertToPrimary(Attribute.SINGLE_IMAGE_MODE_SETTINGS);
  }

  get [Attribute.TARGET_SCANNING_FPS](): number {
    return this.convertToPrimary(Attribute.TARGET_SCANNING_FPS);
  }

  get [Attribute.VIBRATE_ON_SCAN](): boolean {
    return this.convertToPrimary(Attribute.VIBRATE_ON_SCAN);
  }

  get [Attribute.VIDEO_FIT](): BarcodePicker.ObjectFit {
    return this.convertToPrimary(Attribute.VIDEO_FIT);
  }

  get [Attribute.VIEWFINDER_AREA](): SearchArea {
    return this.convertToPrimary(Attribute.VIEWFINDER_AREA);
  }

  get [Attribute.VISIBLE](): boolean {
    return this.convertToPrimary(Attribute.VISIBLE);
  }

  get [Attribute.CONFIGURE_ENGINE_LOCATION](): string {
    return this.convertToPrimary(Attribute.CONFIGURE_ENGINE_LOCATION);
  }

  get [Attribute.CONFIGURE_LICENSE_KEY](): string {
    return this.convertToPrimary(Attribute.CONFIGURE_LICENSE_KEY);
  }

  get [Attribute.CONFIGURE_PRELOAD_ENGINE](): boolean {
    return this.convertToPrimary(Attribute.CONFIGURE_PRELOAD_ENGINE);
  }

  get [Attribute.CONFIGURE_HIGH_QUALITY_BLURRY_RECOGNITION](): boolean {
    return this.convertToPrimary(Attribute.CONFIGURE_HIGH_QUALITY_BLURRY_RECOGNITION);
  }

  get [Attribute.CONFIGURE_PRELOAD_BLURRY_RECOGNITION](): boolean {
    return this.convertToPrimary(Attribute.CONFIGURE_PRELOAD_BLURRY_RECOGNITION);
  }

  get [Attribute.SCAN_SETTINGS_BLURRY_RECOGNITION](): boolean {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_BLURRY_RECOGNITION);
  }

  get [Attribute.SCAN_SETTINGS_CODE_DIRECTION_HINT](): ScanSettings.CodeDirection {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_CODE_DIRECTION_HINT);
  }

  get [Attribute.SCAN_SETTINGS_CODE_DUPLICATE_FILTER](): number {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_CODE_DUPLICATE_FILTER);
  }

  get [Attribute.SCAN_SETTINGS_DEVICE_NAME](): string {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_DEVICE_NAME);
  }

  get [Attribute.SCAN_SETTINGS_ENABLED_SYMBOLOGIES](): Barcode.Symbology[] {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_ENABLED_SYMBOLOGIES);
  }

  get [Attribute.SCAN_SETTINGS_GPU_ACCELERATION](): boolean {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_GPU_ACCELERATION);
  }

  get [Attribute.SCAN_SETTINGS_MAX_NUMBER_OF_CODES_PER_FRAME](): number {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_MAX_NUMBER_OF_CODES_PER_FRAME);
  }

  get [Attribute.SCAN_SETTINGS_SEARCH_AREA](): SearchArea {
    return this.convertToPrimary(Attribute.SCAN_SETTINGS_SEARCH_AREA);
  }

  private convertToPrimary<T extends ConvertedAttribute>(attribute: Attribute): T {
    return <T>convertAttribute(this.schema[attribute], this.view.getAttribute(attribute) ?? undefined);
  }
}
